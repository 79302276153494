<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="tabBar">
		<router-link class="tabBar-item" tag="div" v-for="(item, index) in mobileTab" :key="index" :to="item.path">
			<div style="position:relative">
			<img class="tabBar-item-icon" :src="item.name == activeTab?item.meta.activeIcon:item.meta.normalIcon"
				alt="">
				<span v-if="item.meta.title == '我的'&&unReadNumber > 0"
								class="bottom-list-mark">{{unReadNumber}}</span>
				<span v-if="item.meta.title == '公告'&&noReadNum > 0"
								class="bottom-list-mark">{{noReadNum}}</span>
			</div>
			<span class="tabBar-item-title"
				:class="{'tabBar-item-active':item.name == activeTab}">{{ item.meta.title }}</span>
			
		</router-link>
	</div>
</template>
<script>
	import * as mobileRouter from "@/router/mobileRouter"
	import {
		messageUnreadNumMb,
	} from "@/api/mobile/mine"
	import {
		noReadNum
	} from "@/api/mobile/notice";
	import storage from 'store'
	export default {
		data() {
			return {
				mobileTab: [],
				activeTab: null,
				unReadNumber: 0,
				noReadNum:0,
				identityMb:""
			}
		},
		watch: {
			$route: {
				handler(to, from) {
					this.identityMb = storage.get("identityMb")
					console.log("tabbar-$route", to);
					this.activeTab = to.name;
					if(to.name=='mbNotice'){
						this.getMessagenoReadNum()
					}
					if(to.name=='mbMine'&&this.identityMb=="学生"){
						this.getMessageUnreadNum()
					}
				},
				immediate: true
			},
		},
		created() {
			this.identityMb = storage.get("identityMb")
			if(this.identityMb=="学生"||this.identityMb=="学校老师"){
				this.getMessageUnreadNum()
			}
			this.getTabbar()
			this.getMessagenoReadNum()
		},
		methods: {
			//获取公告未读数量
			getMessagenoReadNum() {
				noReadNum().then((res) => {
					console.log(res)
					if (res.code == 0) {
						this.noReadNum = res.data * 1
					}
				})
			},
			// 获取消息未读数
			getMessageUnreadNum() {
				messageUnreadNumMb().then((res) => {
					if (res.code == 0) {
						this.unReadNumber = res.data * 1
					}
				})
			},
			getTabbar() {
				this.mobileTab = []
				let identityMb = storage.get("identityMb")
				this.mobileTab = mobileRouter.default.filter(item => {
					return !item.meta.hidden && (item.meta.identityMb == identityMb || item.meta.identityMb == "通用")
				});
				console.log("mobileTab", this.mobileTab, identityMb)
			},
		},
	}
</script>
<style lang="scss" scoped>
	.bottom-list-mark {
		position:absolute;
		top:-0.08rem;
		right:-0.1rem;
		min-width: 0.32rem;
		height: 0.32rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #E82D2D;
		border-radius: 50%;
		font-size: 0.24rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}
	.tabBar {
		height: 0.98rem;
		display: flex;
		align-items: center;
		padding: 0.1rem 0 0 0;

		.tabBar-item {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position:relative;
			.tabBar-item-icon {
				width: 0.48rem;
				height: 0.48rem;
				margin-bottom: 0.02rem;
			}

			.tabBar-item-title {
				font-size: 0.2rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.28rem;
				color: #606266;
			}

			.tabBar-item-active {
				color: #0091FF;
			}
		}
	}
</style>
