<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-29 16:52:27
-->
<template>
	<div class="layout">
		<el-container>
			<!-- 主体部分 -->
			<el-main>
				<Main :class="{'isIos-main':platform == 'ios','isAndroid-main': platform == 'android'}" />
			</el-main>
			<el-footer>
				<tabbar />
			</el-footer>
		</el-container>
	</div>
</template>
<script>
	import tabbar from "./components/tabbar";
	import Main from "./components/main";
	export default {
		name: "LayOut",
		components: {
			tabbar,
			Main,
		},
		data() {
			return {
				platform: ""
			};
		},
		computed: {},
		methods: {},
		mounted() {
			if (this.$dd.env.platform !== "notInDingTalk") {
				this.$dd.ready(() => {
					if (this.$dd.env.platform != 'ios') {
						this.platform = 'android'
					} else {
						this.platform = this.$dd.env.platform
					}
				})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.layout {
		.el-container {
			@extend .layout;
			display: flex;
			flex-direction: column;

			.el-main {
				margin: 0 0;
				padding: 0 0;
				background: #F7F8F9;
			}

			.isIos-main {
				height: calc(100vh - 0.98rem - 0.68rem);
				// overflow: auto; //页面整体滚动
				// overflow: hidden; //页面局部滚动
				-webkit-overflow-scrolling: touch;
			}

			.isAndroid-main {
				height: calc(100vh - 0.98rem);
				// overflow: auto; //页面整体滚动
				// overflow: hidden; //页面局部滚动
				-webkit-overflow-scrolling: touch;
			}

			.el-footer {
				height: 0.98rem !important;
				padding: 0 0;
			}
		}
	}
</style>
