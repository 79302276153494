<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-10 16:56:20
-->
<template>
	<div class="main_box">
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		props: {},
		components: {},
		data() {
			return {
			}
		},
		watch: {
			$route: {
				handler(val) {
					console.log("$router", val)
					if (val.meta.title) {
						let headTitle = ""
						headTitle = val.meta.title
						if (this.$dd.env.platform !== "notInDingTalk") {
							this.$dd.ready(() => {
								// 设置标题
								dd.biz.navigation.setTitle({
									title: headTitle, //控制标题文本，空字符串表示显示默认文本
									onSuccess: function(result) {},
									onFail: function(err) {}
								});
							})
						}
					}
				},
				immediate: true,
			}
		},
		methods: {},
		mounted() {
			// console.log("$dd", this.$dd)
		}
	};
</script>
<style lang="scss" scoped>
	.main_box {
		overflow-x: hidden;
		overflow-x: auto;
	}
</style>
